export const environment = {
	production: true,
	version: "2.30.5",
	host: "staging.crowdium.com.ar",
	apiUrl: "https://staging-api.crowdium.com",
	oneSignal: {
		// appId: "5c6aba08-aca4-4c78-9a0b-b6f2de4c329b",
		// apiKey: "MDMyZGM5NjMtN2JiYS00OTgwLTgzN2MtOGI3ZmU2MzA1NDg5",
		appId: null,
		apiKey: null,
	},
	firebase: {
		apiKey: "AIzaSyCKQbJx_No_OjUlQCU21dU-dfFcjPEVQO0",
		authDomain: "staging.crowdium.com.ar",
		databaseURL: "https://crowdium-com-ar-default-rtdb.firebaseio.com",
		projectId: "crowdium-com-ar",
		appId: "1:829641793971:web:d292f131b3c5732311abc6",
		storageBucket: "crowdium-com-ar.appspot.com",
		messagingSenderId: "829641793971",
		measurementId: "G-G6G6FZE1SQ",
		locationId: "us-central",
	},
};
