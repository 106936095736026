<svg class="support-man" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 925.4 1059.7">
	<line class="support-man-0 fill-none stroke-gray-300" stroke-width="7" stroke-linecap="round" stroke-linejoin="round" x1="485" y1="983.3" x2="485" y2="505.3"></line>
	<line class="support-man-0 fill-none stroke-gray-300" stroke-width="7" stroke-linecap="round" stroke-linejoin="round" x1="577.7" y1="960.7" x2="511.5" y2="1048.8"></line>
	<line class="support-man-0 fill-none stroke-gray-300" stroke-width="7" stroke-linecap="round" stroke-linejoin="round" x1="673.6" y1="833.9" x2="600.5" y2="931.1"></line>
	<polyline class="support-man-2 fill-none stroke-dark" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" points="319.3,981.8 306.6,1057.4 186,1038.2 281.4,1019.7 289.4,976.8 "></polyline>
	<polyline class="support-man-2 fill-none stroke-dark" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" points="583.3,910.1 626.9,973.1 527.1,1043.5 582.5,963.7 558.4,927.4 "></polyline>
	<path class="support-man-3 fill-primary" d="M418.6,736.7c-1.4,7.3,120.3,201.2,130,201.2c12.5,0,41.2-21.3,41.2-27.3S535,764.9,535,741.3  S430.6,674.3,418.6,736.7z"></path>
	<line class="support-man-0 fill-none stroke-gray-300" stroke-width="7" stroke-linecap="round" stroke-linejoin="round" x1="397" y1="1037.3" x2="397" y2="505.3"></line>
	<rect x="573" y="579.3" transform="matrix(0.2294 -0.9733 0.9733 0.2294 71.9387 1283.0938)" class="support-man-4 fill-gray-300" width="546.6" height="33.6"></rect>
	<rect x="633.9" y="656.5" transform="matrix(0.2294 -0.9733 0.9733 0.2294 -290.5623 1260.4218)" class="support-man-4 fill-gray-300" width="33.6" height="314.4"></rect>
	<path class="support-man-2 fill-none stroke-dark" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" d="M617.8,475.3C636,393.1,676,298.6,749.2,269.9"></path>
	<path
		class="support-man-2 fill-none stroke-dark"
		stroke-width="2.5"
		stroke-linecap="round"
		stroke-linejoin="round"
		d="M817.5,265.3c30.5,12.2,43.5,49.9,43.5,78c0,157-32.3,314.1-51,326c-33,21-187.1-29-201-62  c-4.1-9.8-4.9-37.4-1-73.1"
	></path>
	<path class="support-man-2 fill-none stroke-dark" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" d="M749.2,269.9c1-12.6,1.1-25.3,0.5-38"></path>
	<path class="support-man-2 fill-none stroke-dark" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" d="M806,185.3c-1,6,1,57,15,89c-3.1,4.9-21.6,13.6-40.5,15.4"></path>
	<path
		class="support-man-2 fill-none stroke-dark"
		stroke-width="2.5"
		stroke-linecap="round"
		stroke-linejoin="round"
		d="M785.4,183.8c-8.2,29.7-26.6,50.4-47.9,50.4c-29,0-52.5-38.3-52.5-85.5c0-7.5,0.6-15.1,1.8-22.5"
	></path>
	<path
		class="support-man-2 fill-none stroke-dark"
		stroke-width="2.5"
		stroke-linecap="round"
		stroke-linejoin="round"
		d="M667.5,124.1c-38.6-8.3-44.5-38.9-44.5-50.9c0-37,33-38,47-46c15.9-9.1,28-26,51-26c22,0,38.5,21,52,28  c23,12,78,10,78,52c0,30-11,38-11,52c0,8,2,11,2,27c0,18.3-16,28.3-27.3,30.5"
	></path>
	<path class="support-man-2 fill-none stroke-dark" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" d="M781.5,139.3c10.2,0,18.5,8.3,18.5,18.5s-8.3,18.5-18.5,18.5"></path>
	<path
		class="support-man-2 fill-none stroke-dark"
		stroke-width="2.5"
		stroke-linecap="round"
		stroke-linejoin="round"
		d="M729.5,457.3c-5.6,13.4-14,37-15.5,59c-15-6-159-71-186-71c-53,0-67,17-67,25c0,15,65,13,74,18s187,121,209,121  c19.9,0,66.7-76.7,77-108.5"
	></path>
	<path class="support-man-2 fill-none stroke-dark" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" d="M846.3,469.8c-5.8,18.5-12.9,31.2-19.3,31.5c-22,1-102-41-108-54c-4-8.6,26-68.5,60.7-101.7"></path>
	<rect y="492.3" class="support-man-4 fill-gray-300" width="510" height="20"></rect>
	<line class="support-man-0 fill-none stroke-gray-300" stroke-width="7" stroke-linecap="round" stroke-linejoin="round" x1="31" y1="1037.3" x2="31" y2="505.3"></line>
	<line class="support-man-0 fill-none stroke-gray-300" stroke-width="7" stroke-linecap="round" stroke-linejoin="round" x1="123" y1="983.3" x2="123" y2="505.3"></line>
	<rect x="374" y="481.3" class="support-man-5 fill-dark" width="115" height="11"></rect>
	<rect x="346.3" y="335.2" transform="matrix(0.9418 -0.3363 0.3363 0.9418 -118.7956 141.1022)" class="support-man-5 fill-dark" width="3.3" height="156.6"></rect>
	<line class="support-man-0 fill-none stroke-gray-300" stroke-width="7" stroke-linecap="round" stroke-linejoin="round" x1="747.3" y1="846.8" x2="817" y2="1056.2"></line>
	<line class="support-man-0 fill-none stroke-gray-300" stroke-width="7" stroke-linecap="round" stroke-linejoin="round" x1="565.2" y1="808.3" x2="404.8" y2="1021.6"></line>
	<line class="support-man-0 fill-none stroke-gray-300" stroke-width="7" stroke-linecap="round" stroke-linejoin="round" x1="642.2" y1="827.9" x2="712" y2="1037.3"></line>
	<path
		class="support-man-3 fill-primary"
		d="M802.1,672.5C757.5,683.8,622,638.1,609,607.3c-1-2.5-1.6-5.2-2-7.8c-58.9-15.2-122.3-26.3-177.5-26.3  c-90.8,0-165,397.4-165,404.3c7.3,2.1,56.5,9,56.5,9s123.4-251.8,126.8-268.9c12.4,8.6,168.9,89.9,229.3,89.9  c66.2,0,128.3-68,128.3-130.6C805.3,675.7,804.2,674.3,802.1,672.5z"
	></path>
	<path
		class="support-man-1 fill-white"
		opacity=".05"
		d="M802.1,672.5C757.5,683.8,622,638.1,609,607.3c-1-2.5-1.6-5.2-2-7.8c-58.9-15.2-122.3-26.3-177.5-26.3  c-90.8,0-165,397.4-165,404.3c7.3,2.1,56.5,9,56.5,9s123.4-251.8,126.8-268.9c12.4,8.6,168.9,89.9,229.3,89.9  c66.2,0,128.3-68,128.3-130.6C805.3,675.7,804.2,674.3,802.1,672.5z"
	></path>
	<circle cx="710.7" cy="147.4" r="3.8"></circle>
	<path class="support-man-2 fill-none stroke-dark" stroke-width="4.4095" stroke-linecap="round" stroke-miterlimit="10" d="M722.5,198.6c-4.4,1.9-9.2,3.4-13.9,4.1"></path>
</svg>
