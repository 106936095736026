import { Component, OnInit } from "@angular/core";

@Component({
	selector: "asset-support-man",
	templateUrl: "./support-man.asset.html",
	styleUrls: ["./support-man.asset.scss"],
})
export class SupportManAsset implements OnInit {
	constructor() {}

	ngOnInit(): void {}
}
